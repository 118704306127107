.hero {
  min-height: 60vh;
  background: linear-gradient(to right,rgb(222, 218, 218),rgb(182, 179, 179),rgb(162, 155, 155),rgb(128, 128, 128),rgb(100, 99, 99));
}

/* title */
.title {
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.title span {
  padding: 0 1rem;
  border-bottom: 0.4rem solid yellowgreen;
}

/* icons */
.icons {
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
.icons i {
  font-size: 5rem;
}

.icons div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

/* Project section */
.projects {
  background: rgba(231, 213, 118, 0.41);
}

/* contact me section */
.contact {
  background: rgba(93, 203, 54, 0.62);
}

/* up button  */
.goup {
  position: fixed;
  bottom: 125px;
  right: 20px;
}

.test {
  background: red;
}

ul {
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  ul {
    display: flex;
    flex-direction: row;
  }
}
.to-nav{
  background:linear-gradient(to right,rgb(222, 218, 218),rgb(182, 179, 179),rgb(162, 155, 155),rgb(128, 128, 128),rgb(100, 99, 99)) ;
}
.layout{
  min-height:75vh;
}
